<template>
  <v-popover
    ref="popover"
    popoverClass="popover-edit-Intervenants"
    :placement="placement"
    offset="0px"
    :handleResize="true"
    :auto-hide="true"
  >
    <span ref="iconth"></span>
    <template slot="popover" v-if="pop">
      <div class="icon-ferme-popover">
        <div class="flex">
          <div class="type">
            {{ $options.filters.formatName(type) }}
          </div>
          <div class="icon-ferme" @click.prevent.stop="outside" title="Fermer">
            <font-awesome-icon icon="times-circle" />
          </div>
        </div>
      </div>
      <hr class="line-ferme" />
      <div class="tooltip-edit-content">
        <div
          v-if="getProjectsProcessing"
          class="init-loading three-dots-loading size ml-2"
        >
          Chargement en cours
        </div>
        <template v-else>
          <div
            class="flex space-between align-center"
            v-for="item in getFieldValueSubcategory"
            :key="item.value"
          >
            <div class="bold">{{ item.name }}</div>
            <div class="flex flex-end">
              <div class="div-margin-left">
                {{ item.value ? item.value : '-' }}
              </div>
            </div>
          </div>
        </template>
      </div>
    </template>
  </v-popover>
</template>

<script>
import Vue from 'vue'
import { VPopover } from 'v-tooltip'
import { mapActions, mapGetters } from 'vuex'

Vue.component('v-popover', VPopover)

export default {
  name: 'TooltipSurfaceIso',
  props: {
    placement: {
      type: String,
      default: 'auto'
    },
    projects: { required: true },
    type: { required: true },
    pop: { required: false }
  },
  data() {
    return { clickDelete: false, totalHt: null }
  },
  methods: {
    ...mapActions(['fetchFieldValueSubcategories']),
    outside() {
      this.$refs.popover.hide()
      this.$emit('close')
    }
  },
  computed: {
    ...mapGetters(['getFieldValueSubcategory', 'getProjectsProcessing'])
  },
  filters: {
    formatName: function(type) {
      switch (type) {
        case 'ecs_dash':
          return 'Ballon Tampon'
        case 'ro_dash':
          return 'Chauffage Appoint'
        case 'rr_dash':
          return 'Kit Bi-zone'
        case 'vmc_dash':
          return 'Ballon'
        case 'iso_dash':
          return 'Isolation'
        case 'chauff_dash':
          return 'Chauffage'
        case 'ambiance_dash':
          return 'Ambiance'
        case 'type_dash':
          return 'Type'
        case 'info_vmc_dash':
          return 'VMC'
        default:
          return ''
      }
    }
  },
  watch: {
    totalHt() {
      this.$nextTick(() => {
        if (this.pop == true) {
          this.$refs.iconth.click()
        }
      })
    }
  },

  mounted() {
    this.totalHt = this.pop
    this.fetchFieldValueSubcategories({
      project_id: this.projects.id,
      field: this.type
    })
  }
}
</script>

<style lang="scss">
.tooltip {
  &.popover-edit-Intervenants {
    opacity: 1;
    display: block !important;
    z-index: 10000;
    .tooltip-inner {
      width: 325px;
      text-align: initial;
      font-size: 12px;
      max-width: 340px;
      .close-tooltip {
        position: absolute;
        top: 3px;
        right: 8px;
        color: #656565;
        cursor: pointer;
        &:hover {
          color: black;
        }
      }
      .form-group {
        margin: 0 16px 10px;
      }

      .tooltip-edit-content {
        padding: 10px 10px;
        .flex {
          display: flex;
        }
        .flex-end {
          justify-content: flex-end;
        }
        .align-center {
          justify-items: center;
        }
        .div-margin-left {
          margin: auto;
          // width: 100px;
          margin-left: -8px;
          text-align: center;
        }
        .bold {
          font-weight: bold;
        }
        .div-margin-right {
          width: 100px;
          margin-left: 6px;
          text-align: center;
        }
        .space-between {
          justify-content: space-between;
        }
        .surface-header-th {
          font-weight: bold;
          display: flex;
          justify-content: flex-end;
          margin-bottom: 12px;
          .surface-header-td-left {
            width: 100px;
            margin-left: 0px;
            border-bottom: 1px solid #d5d5d5;
            text-align: center;
            color: #b0b0b0;
          }
          .surface-header-td-right {
            width: 100px;
            margin-left: 6px;
            border-bottom: 1px solid #d5d5d5;
            text-align: center;
            color: #b0b0b0;
          }
        }
        .surface {
          display: flex;
          .label {
            text-align: start;
            font-weight: bold;
          }
          .notBold {
            margin-left: 5px;
            font-weight: normal;
          }
        }
      }
      .icon-ferme-popover {
        text-align: end;
        font-size: 18px;
        // margin-top: -8px;
        .flex {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        &.style {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
        .type {
          font-weight: bold;
          color: #404346;
          font-size: 15px;
          &.style-type {
            margin-right: 90px;
          }
        }
        .icon-ferme {
          color: #c1c1c1;
          cursor: pointer;
          position: absolute;
          right: 7px;
        }
        .size {
          font-size: 12px;
        }
      }
      .line-ferme {
        margin-bottom: 9px;
        margin-top: 5px;
      }
    }

    .tooltip-arrow {
      width: 0;
      height: 0;
      border-style: solid;
      position: absolute;
      margin: 5px;
      border-color: black;
      z-index: 1;

      border-color: black !important;
      background: white !important;
      border-width: 1px !important;
      border-left-color: rgba(0, 0, 0, 0.2) !important;
      border-right-color: rgba(0, 0, 0, 0.2) !important;
      border-top-color: rgba(0, 0, 0, 0.2) !important;
      border-bottom-color: rgba(0, 0, 0, 0.2) !important;

      width: 10px;
      height: 10px;
      z-index: -2;
      transform: rotateZ(45deg);
    }

    &[x-placement^='top'] {
      margin-bottom: 5px;

      .tooltip-arrow {
        border-width: 5px 5px 0 5px;
        border-left-color: transparent !important;
        border-right-color: transparent !important;
        border-bottom-color: transparent !important;
        bottom: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='bottom'] {
      margin-top: 5px;

      .tooltip-arrow {
        top: -5px;
        left: calc(50% - 5px);
        margin-top: 0;
        margin-bottom: 0;
      }
    }

    &[x-placement^='right'] {
      margin-left: 5px;

      .tooltip-arrow {
        left: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &[x-placement^='left'] {
      margin-right: 5px;

      .tooltip-arrow {
        right: -5px;
        top: calc(50% - 5px);
        margin-left: 0;
        margin-right: 0;
      }
    }

    &.popover {
      $color: #f9f9f9;
      max-width: unset;
      border-radius: 0.1rem;
      .popover-inner {
        background: $color;
        color: black;
        border-radius: 5px;
        box-shadow: 0 5px 30px rgba(black, 0.1);
      }

      .popover-arrow {
        border-color: $color;
      }
    }

    &[aria-hidden='true'] {
      visibility: hidden;
      opacity: 0;
      transition: opacity 0.15s, visibility 0.15s;
    }

    &[aria-hidden='false'] {
      visibility: visible;
      opacity: 1;
      transition: opacity 0.15s;
    }
  }
}
.v-popover {
  .trigger {
    display: block !important;
    position: relative;
    width: 100%;
    height: 100%;
  }
}
</style>
